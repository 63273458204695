import './App.css';
import { Button, Col, Layout, Menu, Row } from 'antd';
import { PRIVATE_ROUTES, PUBLIC_ROUTES, ROUTE_KEYS } from '../../routes';
import { PrivateRoute } from '../Wrapper/Wrapper';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navigation from '../Navigation/Navigation';
import NoMatch from '../NoMatch';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSyncExternalStore } from 'react';
import logo from '../../assets/img/logo.png';
import axios from 'axios';
import { API_URL } from '../../Constants';
import ListMenu from '../Inventory/ListMenu';

const { Header } = Layout;

const mapState = state => ({
  users: state.users,
  permissions: state.permissions,
});

const mapDispatch = dispatch => ({
  refreshPermissions: dispatch.permissions.refreshPermissions,
  refreshUsers: dispatch.users.refreshUsers,
});

const App = props => {
  const [loggedIn, setLoggedIn] = useState();
  const [listPermissions, setListPermissions] = useState({});

  useEffect(() => {
    if (localStorage.getItem('claresse_token')) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  });

  useEffect(() => {
    if (!localStorage.getItem('claresse_user_id')) return;
  }, [loggedIn]);

  useLayoutEffect(() => {
    setListPermissions(hasPermissions());
  }, [localStorage]);

  function hasPermissions() {
    const inbound = localStorage.getItem('can_view_inbound') === 'true';
    const outbound = localStorage.getItem('can_view_outbound') === 'true';
    const stock = localStorage.getItem('can_view_stock') === 'true';
    return inbound || outbound;
  }

  useEffect(() => {
    console.log(listPermissions);
  }, [listPermissions]);

  const doLogout = () => {
    localStorage.clear();

    window.location.href = '/';
  };

  return (
    <div className="App">
      {loggedIn && (
        <Header style={{ padding: '0 25px' }}>
          <Row gutter={16} align="space-between">
            <Col span={9}>
              <Row>
                <Col style={{ color: '#fff', fontSize: '20px' }}>
                  <img
                    alt=""
                    src={logo}
                    style={{
                      display: 'inline-block',
                      width: '40px',
                      marginRight: '20px',
                      textAlign: 'center',
                    }}
                  />
                  <div style={{ display: 'inline-block' }}>Claresse Klantenportaal</div>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <ListMenu />
            </Col>
            <Col span={9}>
              <Row gutter={16} justify="end">
                <Col style={{ color: '#fff', display: 'flex', justifyContent: 'flex-end' }}>
                  Ingelogd als {localStorage.getItem('claresse_username')}
                </Col>
                <Col
                  onClick={doLogout}
                  style={{
                    color: '#fff',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Uitloggen
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
      )}

      <Layout style={{ minHeight: '100vh' }}>
        {/* <Navigation /> */}

        <Switch>
          {Object.values(PRIVATE_ROUTES).map((route, index) => (
            <PrivateRoute key={index} {...route} />
          ))}

          {Object.values(PUBLIC_ROUTES).map((route, index) => (
            <Route key={index} {...route} />
          ))}

          <Route component={NoMatch} />
        </Switch>
      </Layout>
    </div>
  );
};

export default withRouter(connect(mapState, mapDispatch)(App));
