import {API_URL} from '../../Constants';
import {Alert, Button, Form, Input} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import React, {useState} from 'react';
import axios from 'axios';
import xhr from '../../helpers/xhr';

const mapState = state => ({
    permissions: state.permissions,
    users: state.users,
});

const mapDispatch = dispatch => ({
    refreshPermissions: dispatch.permissions.refreshPermissions,
    refreshUsers: dispatch.users.refreshUsers,
});

const LoginForm = props => {
    const [showError, setShowError] = useState(false);
    const [form] = Form.useForm();


    const onFinish = async values => {
        try {
            const {data} = await axios.post(`${API_URL}/auth/login/`, {
                username: values.username,
                password: values.password,
            });

            localStorage.setItem('claresse_token', data.token);
            localStorage.setItem('claresse_username', values.username);
            localStorage.setItem('claresse_user_id', data.user.id)
            localStorage.setItem('claresse_user', data.user)
            localStorage.setItem('can_view_inbound', data.user.can_view_inbound);
            localStorage.setItem('can_view_outbound', data.user.can_view_outbound);
            localStorage.setItem('can_view_stock', data.user.can_view_stock);

            await props.refreshPermissions

            window.location.reload();
            props.redirectAfterLogin();
        } catch (error) {
            console.log(error);
            setShowError(true);
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            {showError && (
                <div style={{marginBottom: 20}}>
                    <Alert
                        message="Inloggen mislukt"
                        description="Ongeldige gebruikersnaam en/of wachtwoord."
                        type="error"
                        showIcon
                    />
                </div>
            )}
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item name="username" rules={[{required: true, message: 'Voer a.u.b. je gebruikersnaam in.'}]}>
                    <Input prefix={<UserOutlined/>} placeholder="Gebruikersnaam"/>
                </Form.Item>

                <Form.Item name="password" rules={[{required: true, message: 'Voer a.u.b. je wachtwoord in.'}]}>
                    <Input prefix={<LockOutlined/>} type="password" placeholder="Wachtwoord"/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Inloggen
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default connect(mapState, mapDispatch)(LoginForm);
