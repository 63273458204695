import { Col, Layout, Row } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LoginForm from './LoginForm';
import React, { useEffect } from 'react';
import logo from '../../assets/img/logo.png';

const { Content } = Layout;

const mapState = state => ({
  users: state.users,
});

const mapDispatch = dispatch => ({
  refreshUsers: dispatch.users.refreshUsers,
});

function Login(props) {
  const { history } = props;

  const redirectAfterLogin = () => {
    history.push('/voorraad');
  };

  useEffect(() => {
    if (localStorage.getItem('claresse_token')) {
      if (localStorage.getItem('can_view_stock') === 'true') {
        history.push('/voorraad');
      } else if (localStorage.getItem('can_view_inbound') === 'true') {
        history.push('/inbound');
      } else if (localStorage.getItem('can_view_outbound') === 'true') {
        history.push('/outbound');
      }
      else {
        // if user is logged in but has no permissions redirect to stock, as API will return no data
        history.push('/voorraad');
      }
    }
  }, [history]);

  return (
    <Layout>
      <Content
        style={{
          background: '#0f4f5d',
        }}
      >
        <Row type="flex" justify="center" align="middle" style={{ height: '100vh', width: '100vw' }}>
          <Col
            lg={6}
            xs={{
              span: 22,
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <img
                alt=""
                src={logo}
                style={{
                  marginTop: 20,
                  width: '120px',
                  textAlign: 'center',
                  paddingBottom: '30px',
                }}
              />
            </div>
            <div style={{ backgroundColor: '#fff', padding: 20 }}>
              <LoginForm redirectAfterLogin={redirectAfterLogin} />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default withRouter(connect(mapState, mapDispatch)(Login));
